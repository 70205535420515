<template>
    <div class="container text-center">
        <img
            src="../assets/autopay.svg"
            class="mt-4"
            alt="Setup AutoPay"
        >
        <h4
            class="mt-5"
            v-html="$t('pages.SetupAutoPay.Promo.SetupCredit')"
        />
        <base-button
            class="mt-5"
            type="button"
            button-classes="btn btn-primary"
            @click="setupAutoPay"
        >
            {{ $t('pages.SetupAutoPay.setupAutoPay') }}
        </base-button>
        <base-button
            class="mt-2"
            button-classes="btn btn-tertiary"
            @click="notNow"
        >
            {{ $t('pages.SetupAutoPay.notNow') }}
        </base-button>
        <div class="mt-1">
            <slot />
        </div>
        <div>
            <p class="small text-muted">
                {{ $t('pages.SetupAutoPay.Promo.SetupCreditDetails') }}
            </p>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import { postLoginNavigation } from '@/utils/sharedLogic'
    import { RouteNames } from '../routes/router'

    export default {
        name: RouteNames.PROMO_SETUP_AUTOPAY_CREDIT,
        components: {
            BaseButton,
        },
        mounted: function () {
            this.$logEvent('view_setup_autopay_promo_landing_page')
        },
        methods: {
            setupAutoPay: function () {
                this.$logEvent('click_setup_autopay_promo_landing_page_cta')
                this.$router.push({ name: RouteNames.ENROLL_IN_AUTOPAY })
            },
            notNow: function () {
                this.$logEvent('click_setup_autopay_promo_landing_page_dismiss')
                postLoginNavigation()
            },
        },
    }
</script>
